:root {

    /* indigo */
    --indigo-100: #B3A0FFff;
    --indigo-200: #AA95FEff;
    --indigo-300: #A28BFCff;
    --indigo-400: #9980FBff;
    --indigo-500: #9176FAff;
    --indigo-600: #886BF8ff;
    --indigo-700: #8061F7ff;
    --indigo-800: #7756F6ff;
    --indigo-900: #6F4CF4ff;
    --indigo-1000: #6641F3ff;
    --indigo-prime: #5533d4;
    /* indigo */

    /* robin */
    --robin-100: #44DCECff;
    --robin-200: #3CD3E3ff;
    --robin-300: #35CADAff;
    --robin-400: #2DC1D1ff;
    --robin-500: #26B8C8ff;
    --robin-600: #1EB0BFff;
    --robin-700: #17A7B6ff;
    --robin-800: #0F9EADff;
    --robin-900: #0895A4ff;
    --robin-1000: #008C9Bff;

    /* robin */

    /* Red */
    --red-100: #F9BEC7;
    --red-200: #F7ABB7;
    --red-300: #F598A7;
    --red-400: #F48497;
    --red-500: #F27187;
    --red-600: #F05E77;
    --red-700: #EE4B67;
    --red-800: #ED3757;
    --red-900: #EB2447;
    --red-1000: #E91137;
      /* Red */

    /* Air */
    --air-100: #F2F6F9ff;
    --air-200: #E0E9EFff;
    --air-300: #CEDBE5ff;
    --air-400: #BDCEDAff;
    --air-500: #ABC0D0ff;
    --air-600: #99B3C6ff;
    --air-700: #87A5BCff;
    --air-800: #7698B1ff;
    --air-900: #648AA7ff;
    --air-1000: #527D9Dff;
    /* Air */

    /* Gray */
    --gray-100: #5F5A5Aff;
    --gray-200: #565151ff;
    --gray-300: #4D4848ff;
    --gray-400: #434040ff;
    --gray-500: #3A3737ff;
    --gray-600: #312E2Eff;
    --gray-700: #282525ff;
    --gray-800: #1E1D1Dff;
    --gray-900: #151414ff;
    --gray-1000: #0C0B0Bff;
    /* Gray */

    /* chart colors */
    --imperial-red: #f94144ff;
    --orange-crayola: #f3722cff;
    --carrot-orange: #f8961eff;
    --coral: #f9844aff;
    --saffron: #f9c74fff;
    --pistachio: #90be6dff;
    --zomp: #43aa8bff;
    --dark-cyan: #4d908eff;
    --paynes-gray: #577590ff;
    --cerulean: #277da1ff;
    --true-blue: #586ba4ff;
    --marian-blue: #324376ff;
    --flax: #f5dd90ff;
    --atomic-tangerine: #f68e5fff;
    --bittersweet: #f76c5eff;
    --yinmn-blue: #355070ff;
    --chinese-violet: #6d597aff;
    --china-rose: #b56576ff;
    --light-coral: #e56b6fff;
    --buff: #eaac8bff;
    /* chart colors */

}
.indigo-100 { 
    background-color: #B3A0FFff;
}
.indigo-200 { 
    background-color: #AA95FEff;
}
.indigo-300 { 
    background-color: #A28BFCff;
}
.indigo-400 { 
    background-color: #9980FBff;
}
.indigo-500 { 
    background-color: #9176FAff;
}
.indigo-600 { 
    background-color: #886BF8ff;
}
.indigo-700 { 
    background-color: #8061F7ff;
}
.indigo-800 { 
    background-color: #7756F6ff;
}
.indigo-900 { 
    background-color: #6F4CF4ff;
}
.indigo-1000 { 
    background-color: #6641F3ff;
}
/* robin */
.robin-100 { 
    background-color: #44DCECff;
}
.robin-200 { 
    background-color: #3CD3E3ff;
}
.robin-300 { 
    background-color: #35CADAff;
}
.robin-400 { 
    background-color: #2DC1D1ff;
}
.robin-500 { 
    background-color: #26B8C8ff;
}
.robin-600 { 
    background-color: #1EB0BFff;
}
.robin-700 { 
    background-color: #17A7B6ff;
}
.robin-800 { 
    background-color: #0F9EADff;
}
.robin-900 { 
    background-color: #0895A4ff;
}
.robin-1000 { 
    background-color: #008C9Bff;
}
/* robin */

/* air */
.air-100 { 
    background-color: #F2F6F9ff;
}
.air-200 { 
    background-color: #E0E9EFff;
}
.air-300 { 
    background-color: #CEDBE5ff;
}
.air-400 { 
    background-color: #BDCEDAff;
}
.air-500 { 
    background-color: #ABC0D0ff;
}
.air-600 { 
    background-color: #99B3C6ff;
}
.air-700 { 
    background-color: #87A5BCff;
}
.air-800 { 
    background-color: #7698B1ff;
}
.air-900 { 
    background-color: #648AA7ff;
}
.air-1000 { 
    background-color: #527D9Dff;
}
/* air */

/* gray */
.gray-100 { 
    background-color: #5F5A5Aff;
}
.gray-200 { 
    background-color: #565151ff;
}
.gray-300 { 
    background-color: #4D4848ff;
}
.gray-400 { 
    background-color: #434040ff;
}
.gray-500 { 
    background-color: #3A3737ff;
}
.gray-600 { 
    background-color: #312E2Eff;
}
.gray-700 { 
    background-color: #282525ff;
}
.gray-800 { 
    background-color: #1E1D1Dff;
}
.gray-900 { 
    background-color: #151414ff;
}
.gray-1000 { 
    background-color: #0C0B0Bff;
}
/* gray */

/* red */
.red-100 {
    background: var(--red-100) !important;
}
.red-200 {
    background: var(--red-200) !important;
}
.red-300 {
    background: var(--red-300) !important;
}
.red-400 {
    background: var(--red-400) !important;
}
.red-500 {
    background: var(--red-500) !important;
}
.red-600 {
    background: var(--red-600) !important;
}
.red-700 {
    background: var(--red-700) !important;
}
.red-800 {
    background: var(--red-800) !important;
}
.red-900 {
    background: var(--red-900) !important;
}
.red-1000 {
    background: var(--red-1000) !important;
}
/* red */


/* branding styles */

.color-palette li {
    padding: 20px 20px;
}
.indigo-colors {
    color: white;
}
.robin-colors li {
    color: white;
}
.gray-colors li {
    color: white;
}
/* branding styles */



